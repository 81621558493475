import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import XHR from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { en, ns } from "./translation";

i18next
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector as any)
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(XHR)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    // debug: true,
    returnNull: false,
    returnEmptyString: true,
    fallbackLng: process.env.NODE_ENV === "development" ? "en" : "en",
    load: "languageOnly",
    resources: {
      en,
      // uk: ua,
      // "uk-UA": ua,
      // ua,
    },
    ns,
    defaultNS: "core",
    fallbackNS: "core",
    detection: {
      order: ["querystring", "cookie", "navigator"],
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18next;
